import styled from "styled-components";
import { Icon } from "../../../../components/Icon/Icon.styled";
import { Text } from "../../../../components/Text/Text.styled";
import { psLightGray } from "../../../../utils/constants";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
`;

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 35px;
`;

export const NumberContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const IconType = styled(Icon)`
  font-size: 18px;
  margin-right: 10px;
`;

export const Number = styled(Text)`
  margin-top: 10px;
`;

export const MapContainer = styled.iframe`
  height: 300px;
  width: 100%;
  max-width: 500px;
  border: none;
  margin-bottom: 15px;
  box-shadow: 0 0 20px ${psLightGray};
  border-radius: 25px;

  @media screen and (min-width: 769px) {
    margin-left: 15px;
  }
`;
