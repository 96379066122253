import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Section from "../../../../components/Section";
import Vault, { VaultProps } from "./Vault";
import Vault0Thumb from "../../../../assets/vault_0_thumb_110.jpg";
import Vault1Thumb from "../../../../assets/vault_1_thumb_110.jpg";
import Vault2Thumb from "../../../../assets/vault_2_thumb_110.jpg";
import Vault3Thumb from "../../../../assets/vault_3_thumb_110.jpg";
import Vault4Thumb from "../../../../assets/vault_4_thumb_110.jpg";

import Vault0Image1 from "../../../../assets/vault_0_1_200.jpg";
import Vault0Image2 from "../../../../assets/vault_0_2_200.jpg";

import Vault1Image1 from "../../../../assets/vault_1_1_200.jpg";
import Vault1Image2 from "../../../../assets/vault_1_2_200.jpg";
import Vault1Image3 from "../../../../assets/vault_1_3_200.jpg";
import Vault1Image4 from "../../../../assets/vault_1_4_200.jpg";

import Vault2Image1 from "../../../../assets/vault_2_1_200.jpg";
import Vault2Image2 from "../../../../assets/vault_2_2_200.jpg";
import Vault2Image3 from "../../../../assets/vault_2_3_200.jpg";
import Vault2Image4 from "../../../../assets/vault_2_4_200.jpg";

import Vault3Image1 from "../../../../assets/vault_3_1_200.jpg";
import Vault3Image2 from "../../../../assets/vault_3_2_200.jpg";

import Vault4Image1 from "../../../../assets/vault_4_1_200.jpg";
import Vault4Image2 from "../../../../assets/vault_4_2_200.jpg";
import { useTranslation } from "react-i18next";
import { Text } from "../../../../components/Text/Text.styled";
import { Link } from "react-scroll";
import { useMediaQuery } from "react-responsive";

const responsive = {
  rest: {
    breakpoint: {
      max: 4000,
      min: 1091,
    },
    items: 2.5,
  },
  mobileS: {
    breakpoint: {
      max: 320,
      min: 0,
    },
    items: 1,
  },
  mobileM: {
    breakpoint: {
      max: 375,
      min: 321,
    },
    items: 1.2,
  },
  mobileL: {
    breakpoint: {
      max: 425,
      min: 376,
    },
    items: 1,
  },
  tabletS: {
    breakpoint: {
      max: 470,
      min: 426,
    },
    items: 1,
  },
  tabletM: {
    breakpoint: {
      max: 550,
      min: 471,
    },
    items: 1.075,
  },
  tabletL: {
    breakpoint: {
      max: 650,
      min: 551,
    },
    items: 1.275,
  },
  tabletLL: {
    breakpoint: {
      max: 768,
      min: 651,
    },
    items: 1.5,
  },
  laptop: {
    breakpoint: {
      max: 859,
      min: 769,
    },
    items: 1.7,
  },
  laptopM: {
    breakpoint: {
      max: 1023,
      min: 850,
    },
    items: 1.9,
  },
  preRest: {
    breakpoint: {
      max: 1090,
      min: 1024,
    },
    items: 2.1,
  },
};

const Vaults = () => {
  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const vaults = [
    {
      id: 0,
      title: "Maxi Cofre MDI 2000 individual",
      specifications: `30 ${t("banknotes-per-minute")} (${t(
        "until"
      )} 7000), GPRS, Ethernet, display LCD...`,
      thumb: Vault0Thumb,
      images: [Vault0Image1, Vault0Image2],
      detailedSpecifications: t("vault-0-detailed-specifications").split("|"),
    },
    {
      id: 1,
      title: "Maxi Cofre MDI 2100 " + t("pack"),
      specifications: `40 ${t("banknotes-per-minute")} (${t(
        "until"
      )} 7000), GPRS, Ethernet, display LCD...`,
      thumb: Vault1Thumb,
      images: [Vault1Image1, Vault1Image2, Vault1Image3, Vault1Image4],
      detailedSpecifications: t("vault-1-detailed-specifications").split("|"),
    },
    {
      id: 2,
      title: "Maxi Cofre MDI 3100 display",
      specifications: `600 ${t("banknotes-per-minute")} (${t(
        "until"
      )} 10000), GPRS, Ethernet, display LCD...`,
      thumb: Vault2Thumb,
      images: [Vault2Image1, Vault2Image2, Vault2Image3, Vault2Image4],
      detailedSpecifications: t("vault-2-detailed-specifications").split("|"),
    },
    {
      id: 3,
      title: "Maxi Cofre MDI 3100 touch",
      specifications: `600 ${t("banknotes-per-minute")} (${t(
        "until"
      )} 10000), Wi-Fi, Ethernet, display touch...`,
      thumb: Vault3Thumb,
      images: [Vault3Image1, Vault3Image2],
      detailedSpecifications: t("vault-3-detailed-specifications").split("|"),
    },
    {
      id: 4,
      title: "Maxi Cofre MDI 3200 k-touch",
      specifications: `360 ${t("banknotes-per-minute")} (${t(
        "until"
      )} 10000), Wi-Fi, Ethernet, display touch...`,
      thumb: Vault4Thumb,
      images: [Vault4Image1, Vault4Image2],
      detailedSpecifications: t("vault-4-detailed-specifications").split("|"),
    },
  ] as VaultProps[];

  return (
    <Section title={t("our-safes")} id="our-safes">
      <Carousel
        responsive={responsive}
        deviceType=""
        ssr={true}
        slidesToSlide={1}
        draggable={false}
        swipeable={false}
        arrows
        renderArrowsWhenDisabled={false}
        minimumTouchDrag={0}
      >
        {vaults.map((v, i) => {
          return (
            <Vault
              key={i}
              id={v.id}
              title={v.title}
              specifications={v.specifications}
              thumb={v.thumb}
              images={v.images}
              detailedSpecifications={v.detailedSpecifications}
            />
          );
        })}
      </Carousel>
      <br></br>
      <br></br>
      <Text as="p">
        {t("vaults-text1")}
        <Link
          to="contact"
          smooth={true}
          duration={500}
          offset={isMobile ? -115.5 : -104.5}
        >
          <Text as="span" color="link">
            {t("commercial-department")}
          </Text>
        </Link>
      </Text>
      <br></br>
      <br></br>
    </Section>
  );
};

export default Vaults;
