import styled from "styled-components";
import { Circle } from "../../../../../components/Header/Header.styled";
import { psGray, psLightGray } from "../../../../../utils/constants";

export const VaultContainer = styled.article`
  max-width: 350px;
  background-color: ${psLightGray};
  box-shadow: 0 0 10px ${psGray};
  border-radius: 15px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  margin: 10px;
  flex-wrap: wrap;
  min-height: 234px;
`;

export const ImageContainer = styled.figure`
  padding: 10px;
  background-color: white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 400px) {
    width: 100%;
  }
`;

export const AboutContainer = styled.div`
  width: calc(350px - 170px);

  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 435px) {
    width: calc(350px - 200px);
  }

  @media screen and (max-width: 400px) {
    width: 100%;
    margin: 20px 0;
  }
`;

export const HeaderContainer = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: right;
  background-color: white;
`;

export const ModalHeader = styled.header`
  width: 100%;
  padding: 10px 0;
  border-bottom: solid 1px ${psLightGray};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
`;

export const ModalContainer = styled.div`
  width: 90%;
  margin: auto;
`;

export const ModalImg = styled.img`
  display: block;
  margin: auto;
  margin-bottom: 15px;
`;

export const Specification = styled.ul`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const CircleModal = styled(Circle)`
  margin-right: 5px;
`;
