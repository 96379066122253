import { motion } from "framer-motion";
import styled from "styled-components";
import { psGray, psRed } from "../../utils/constants";

export const FirstBar = styled.div`
  width: 100%;
  height: 25px;
  background-color: ${psRed};
`;

export const SecondBar = styled.div`
  width: 100%;
  height: 50px;
  background-color: ${psGray};
`;

interface StickyContainerProps {
  isSticky?: boolean;
}

export const StickyContainer = styled.div<StickyContainerProps>`
  background-color: white;
  width: 100%;
  ${(props) =>
    props.isSticky &&
    `
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 5px -2px ${psGray};
    `};
  padding: 0.25px 0;
  z-index: 5;
`;

export const Container = styled.div`
  width: 95vw;
  max-width: 1050px;
  margin: 25px auto;
  background-color: white;
`;

export const IdentityContainer = styled.div`
  margin: 25px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
`;

export const IdiomsContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  width: 250px;
  height: 88.52px;
  border-left: solid 1px #cccccc;
`;

interface IdiomProps {
  selected?: boolean;
}

export const Idiom = styled(motion.img)<IdiomProps>`
  margin-left: 20px;
  width: 25px;
  height: 16px;

  @media screen and (min-width: 769px) {
    cursor: pointer;
  }

  ${(props) =>
    props.selected
      ? `
    border-radius: 5px;
    border: solid 2px ${psRed};
  `
      : `
      opacity: 0.30;
      border-radius: 5px;
      border: solid 2px white;
  `}
`;

export const Menu = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 35px 0;

  position: -webkit-sticky;
  position: sticky;
  top: 0;
`;

export const Circle = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: ${psRed};
`;

export const MenuItem = styled(motion.li)`
  padding: 7.5px 10px;
  border-radius: 15px;
  user-select: none;

  @media screen and (max-width: 768px) {
    margin: 5px 0px;
  }

  @media screen and (min-width: 769px) {
    cursor: pointer;
  }
`;
