import Section from "../../../../components/Section";
import { Text } from "../../../../components/Text/Text.styled";
import { psRed } from "../../../../utils/constants";
import {
  Argument,
  Arguments,
  IconContainer,
  TextContainer,
} from "./WhoWeAre.styled";
import { Icon } from "../../../../components/Icon/Icon.styled";
import { useTranslation } from "react-i18next";

const WhoWeAre = () => {
  const { t, i18n } = useTranslation();

  const arguments1 = [
    {
      iconContainerColor: "#FFCACA",
      iconColor: psRed,
      icon: "fas fa-lock",
      title: t("security"),
      description: t("security-argument"),
    },
    {
      iconContainerColor: "#B7E1FF",
      iconColor: "#0064CC",
      icon: "fas fa-stopwatch",
      title: t("agility"),
      description: t("agility-argument"),
    },
  ];

  const arguments2 = [
    {
      iconContainerColor: "#FFEFB7",
      iconColor: "#FFBC39",
      icon: "fas fa-chart-line",
      title: "Feedbacks",
      description: t("feedbacks-argument"),
    },
    {
      iconContainerColor: "#B7FFBA",
      iconColor: "#00A023",
      icon: "fas fa-robot",
      title: t("technology"),
      description: t("technology-argument"),
    },
  ];

  return (
    <Section title={t("who-we-are")} id="who-we-are">
      <Text as="h1">
        {t("we-are")}
        <Text weight="pre-bold" as="span">
          {t("pioneers-and-experts")}
        </Text>
        {t("in-the-development-of")}
        <Text weight="pre-bold" as="span">
          {t("high-performance-smart-safes")}
        </Text>
        {t("we-have-been-in-the")}
        <Text weight="pre-bold" as="span">
          2009
        </Text>
        {t("always-innovating")}
      </Text>
      <Arguments>
        {arguments1.map((a, i) => {
          return (
            <Argument key={i}>
              <IconContainer color={a.iconContainerColor}>
                <Icon className={a.icon} color={a.iconColor} />
              </IconContainer>
              <TextContainer>
                <Text weight="pre-bold" as="h1">
                  {a.title}
                </Text>
                <br></br>
                <Text as="p" align="justify">
                  {a.description}
                </Text>
              </TextContainer>
            </Argument>
          );
        })}
      </Arguments>
      <Arguments>
        {arguments2.map((a, i) => {
          return (
            <Argument key={i + 1}>
              <IconContainer color={a.iconContainerColor}>
                <Icon className={a.icon} color={a.iconColor} />
              </IconContainer>
              <TextContainer>
                <Text weight="pre-bold" as="h1">
                  {a.title}
                </Text>
                <br></br>
                <Text as="p" align="justify">
                  {a.description}
                </Text>
              </TextContainer>
            </Argument>
          );
        })}
      </Arguments>
    </Section>
  );
};

export default WhoWeAre;
