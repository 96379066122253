import { motion } from "framer-motion";
import styled from "styled-components";

interface IconProps {
  color: string;
}

export const Icon = styled(motion.i)<IconProps>`
  color: ${(props) => props.color};
`;
