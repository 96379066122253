import styled from "styled-components";
import { psLightGray } from "../../utils/constants";
import { Icon } from "../Icon/Icon.styled";

export const Footer = styled.footer`
  width: 100%;
  background-color: ${psLightGray};
  margin-top: 100px;
  padding: 100px 0;
`;

export const Img = styled.img`
  margin: auto;
  display: block;
`;

export const Container = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  height: 100%;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const IconBackContainer = styled.div`
  user-select: none;
  text-align: center;
  cursor: pointer;
  &:hover {
    transform: scale(0.95);
  }
  margin: 25px 0;
`;

export const IconBack = styled(Icon)`
  font-size: 26px;
`;
