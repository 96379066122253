import Header from "../../components/Header";
import Banner from "./components/Banner";
import Vaults from "./components/Vaults";
import HowWorks from "./components/HowWorks";
import WhoWeAre from "./components/WhoWeAre";
import Support from "./components/Support";
import Contact from "./components/Contact";
import Footer from "../../components/Footer";
import PWAQuestion from "./components/PWAQuestion";

const Home = () => {
  return (
    <>
      <Header />
      <main>
        <Banner />
        <Vaults />
        <HowWorks />
        <WhoWeAre />
        <Support />
        <Contact />
      </main>
      <Footer />
    </>
  );
};

export default Home;
