import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import Section from "../../../../components/Section";
import { Text } from "../../../../components/Text/Text.styled";
import { ButtonContainer } from "./Support.styled";

const Support = () => {
  const { t, i18n } = useTranslation();

  return (
    <Section title={t("technical-support")} id="technical-support">
      <Text as="p">
        {t("equipment-problems")}
        <br></br>
        <Text weight="pre-bold" as="span">
          {t("contact-us")}
        </Text>
        {t("throught-the-button")}
        <br></br>
        <br></br>
        {t("our-team-is-available")}
        <Text weight="pre-bold" as="span">
          {t("monday-to-friday")}
        </Text>
      </Text>
      <ButtonContainer>
        <Button
          text={t("request")}
          to="https://atendimento.fieldcontrol.com.br/prime-security-solutions"
        ></Button>
      </ButtonContainer>
    </Section>
  );
};

export default Support;
