import Section from "../../../../components/Section";
import { Background, Container, Img, Scrollable } from "./HowWorks.styled";
import { useTranslation } from "react-i18next";

const atualLng = localStorage.getItem("psIdiom") || "pt";
const image = require(`../../../../assets/how-works-${atualLng}.png`);

const HowWorks = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Section title={t("how-works")} id="how-works"></Section>
      <Scrollable>
        <Background>
          <Container>
            <Img src={image} alt="How works" loading="lazy" />
          </Container>
        </Background>
      </Scrollable>
    </>
  );
};

export default HowWorks;
