import styled from "styled-components";
import { psBlack } from "../../utils/constants";

interface TextProps {
  size?: TextSizeProp;
  weight?: TextWeightProp;
  uppercase?: boolean;
  color?: TextColorProp;
  align?: TextAlignProp;
}

export const Text = styled.div<TextProps>`
  color: ${(props) => selectTextColor(props.color)};
  font-size: ${(props) => selectTextSize(props.size)};
  font-weight: ${(props) => selectTextWeight(props.weight)};
  ${(props) => props.uppercase && "text-transform: uppercase;"}
  text-align: ${(props) => props.align};

  user-select: none;

  ${(props) =>
    props.color &&
    props.color === "link" &&
    `&:hover { 
        color: #529dff;
        cursor: pointer;
    }`}
`;

type TextAlignProp = "left" | "center" | "justify";

type TextColorProp = "white" | "psBlack" | "link";

const selectTextColor = (textColor?: TextColorProp) => {
  switch (textColor) {
    case "white":
      return "white";
    case "link":
      return "#1A73E8";
    default:
      return psBlack;
  }
};

type TextWeightProp = "light" | "normal" | "pre-bold" | "bold";

const selectTextWeight = (textWeight?: TextWeightProp) => {
  //needs add on css styles
  switch (textWeight) {
    case "light":
      return "200";
    case "pre-bold":
      return "700";
    case "bold":
      return "900";
    default:
      return "400";
  }
};

type TextSizeProp =
  | "accessible"
  | "small"
  | "normal"
  | "large"
  | "x-large"
  | "button";

const selectTextSize = (textSizeProp?: TextSizeProp) => {
  switch (textSizeProp) {
    case "accessible":
      return "10px";
    case "button":
      return "14px";
    case "large":
      return "26px";
    case "x-large":
      return "32px";
    default:
      return "1.6rem";
  }
};
