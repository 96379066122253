import styled from "styled-components";
import { Icon } from "../Icon/Icon.styled";

export const MenuBars = styled(Icon)`
  font-size: 23px;
  padding: 6px;
  border-radius: 5px;

  @media screen and (min-width: 768px) {
    cursor: pointer;
  }
`;

export const MenuHeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 89%;
  height: 108px;
`;

export const MenuItemMobileContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MobileIdiomsContainer = styled.div`
  display: flex;
  width: 91%;
  justify-content: center;
  margin: 30px 0 100px 0;
`;
