import styled from "styled-components";
import { psLightGray } from "../../../../utils/constants";

export const Scrollable = styled.div`
  width: 100%;

  @media screen and (max-width: 588px) {
    overflow-x: scroll;
  }
`;

export const Background = styled.div`
  background: ${psLightGray};
  min-width: 550px;
  clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 20px;
`;

export const Img = styled.img`
  width: 100%;
  max-width: 900px;
  min-width: 500px;
  margin: 0 10px;
`;

export const Container = styled.div`
  width: 100%;
  min-width: 500px;
  max-width: 900px;
`;
