import { Line, Section } from "./Section.styled";
import { Text } from "../Text/Text.styled";

export interface SectionProps {
  title?: string;
  id?: string;
  isModal?: boolean;
}

const SectionComponent: React.FC<SectionProps> = ({
  title,
  id,
  children,
  isModal,
}) => {
  return (
    <Section id={id} isModal={isModal}>
      <Text
        weight={isModal ? "pre-bold" : "bold"}
        size={isModal ? "normal" : "large"}
        as="h1"
      >
        {title}
      </Text>
      <Line />
      {children}
    </Section>
  );
};

export default SectionComponent;
