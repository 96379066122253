import styled from "styled-components";
import Banner from "../../../../assets/banner_full.png";

export const Image = styled.div`
  width: 100%;
  height: 260px;
  background: url(${Banner});
  background-size: cover;
  background-attachment: fixed;
`;

export const Container = styled.div`
  width: 90%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
