import styled from "styled-components";
import { SectionProps } from ".";
import { psRed } from "../../utils/constants";

export const Section = styled.section<SectionProps>`
  margin: 40px auto;
  ${(props) =>
    !props.isModal &&
    `
    margin-top: 100px;
    width: 90vw;
    max-width: 1000px;`
  }
`;

export const Line = styled.div`
  width: 100px;
  height: 2px;
  margin-top: 4px;
  margin-bottom: 30px;
  background-color: ${psRed};
`;
