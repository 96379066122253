import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./translations.json";

let lng = localStorage.getItem("psIdiom");

if (!lng) {
  localStorage.setItem("psIdiom", "pt");
  lng = "pt";
}

i18n.use(initReactI18next).init({
  lng,
  resources: translations,
  fallbackLng: "pt",
});

export default i18n;
