import { useTranslation } from "react-i18next";
import Section from "../../../../components/Section";
import { Text } from "../../../../components/Text/Text.styled";
import {
  ContactsContainer,
  Container,
  IconType,
  MapContainer,
  NumberContainer,
} from "./Contact.styled";

const Contact = (setInViewId: any) => {
  const { t, i18n } = useTranslation();

  //0 - Tel
  //1 - Whatsapp
  //2 - Email

  const contacts = [
    {
      title: t("commercial"),
      numbers: [
        {
          number: "(11) 97508-9122",
          type: 1,
          link: "https://wa.me/5511975089122?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20os%20Produtos%20e%20Soluções%20da%20Prime%20Security.",
        },
        {
          number: "(11) 94328-7178",
          type: 1,
          link: "https://wa.me/5511943287178?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20os%20Produtos%20e%20Soluções%20da%20Prime%20Security.",
        },
      ],
    },
    {
      title: t("technical-support"),
      numbers: [
        {
          number: "(11) 93428-8008",
          type: 1,
          link: "https://wa.me/5511934288008?text=Olá,%20preciso%20de%20um%20suporte%20técnico%20para%20o%20meu%20Equipamento%20Prime.",
        },
        {
          number: "(11) 98856-6608",
          type: 0,
          link: "tel:988566608",
        },
      ],
    },
    {
      title: "Emails",
      numbers: [
        {
          number: "sac@primesol.com.br",
          type: 2,
          link: "mailto:sac@primesol.com.br",
        },
        {
          number: "comercial@primesol.com.br",
          type: 2,
          link: "mailto:comercial@primesol.com.br",
        },
        {
          number: "contato@primesol.com.br",
          type: 2,
          link: "mailto:contato@primesol.com.br",
        },
      ],
    },
  ];

  return (
    <Section title={t("contact-and-localization")} id="contact">
      <Container>
        <div className="mobile-row">
          {contacts.map((c, i) => {
            return (
              <ContactsContainer key={i}>
                <Text as="h1" weight="pre-bold">
                  {c.title}
                </Text>
                {c.numbers.map((n) => (
                  <NumberContainer>
                    {n.type === 0 ? (
                      <IconType className="fas fa-phone-alt" color="#3E7ED1" />
                    ) : n.type === 1 ? (
                      <IconType className="fab fa-whatsapp" color="#24CC63" />
                    ) : (
                      <IconType className="fas fa-envelope" color="#FF3A3A" />
                    )}
                    <Text as="a" href={n.link} target="_blank" color="link">
                      {n.number}
                    </Text>
                  </NumberContainer>
                ))}
              </ContactsContainer>
            );
          })}
        </div>
        <div className="center-mobile">
          <MapContainer
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2235.0615132167723!2d-46.398588693607564!3d-23.750889172135846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce12de23c7a0c1%3A0xcd7017274c2d65fa!2sAv.%20Dom%20Pedro%20I%2C%20743%20-%20Jardim%20Novo%20Horizonte%2C%20Rio%20Grande%20da%20Serra%20-%20SP%2C%2009450-000%2C%20Brasil!5e0!3m2!1spt-BR!2sus!4v1643405920179!5m2!1spt-BR!2sus"
            allowFullScreen={false}
            loading="lazy"
          />
          <Text as="p" weight="pre-bold" align="center">
            Av. Dom Pedro I, 743 – {t("buildings")} 1 {t("and")} 3 -{" "}
            {t("center")} - Rio Grande da Serra - SP
          </Text>
          <Text as="p" weight="pre-bold" align="center">
            CEP: 09450-000
          </Text>
        </div>
      </Container>
    </Section>
  );
};

export default Contact;
