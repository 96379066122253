import { Container, Image } from "./Banner.styled";
import { Text } from "../../../../components/Text/Text.styled";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t, i18n } = useTranslation();

  return (
    <Image id="start">
      <Container>
        <Text color="white" weight="bold" size="x-large" as="h1" align="center">
          {t("banner-h1")}
        </Text>
        <div>
          <Text color="white" weight="light" as="p" align="center">
            {t("more")}
            <Text color="white" weight="bold" as="b">
              {t("security-and-agility")}
            </Text>{" "}
            {t("banner-p1-rest-excerpt")}
          </Text>
          <Text color="white" weight="light" as="p" align="center">
            {t("know-our-solutions")}
          </Text>
        </div>
      </Container>
    </Image>
  );
};

export default Banner;
