import {
  Circle,
  Container,
  FirstBar,
  IdentityContainer,
  Idiom,
  IdiomsContainer,
  LogoContainer,
  Menu,
  MenuItem,
  SecondBar,
  StickyContainer,
} from "./Header.styled";
import Logo240 from "../../assets/logo_240.jpg";
import Logo180 from "../../assets/logo_180.png";
import Brasil25 from "../../assets/brasil_25.png";
import Spain25 from "../../assets/spain_25.png";
import USA25 from "../../assets/usa_25.png";
import { Text } from "../Text/Text.styled";
import { psBlack, whileHover, whileTap } from "../../utils/constants";
import { useMediaQuery } from "react-responsive";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import {
  MenuBars,
  MenuHeaderContainer,
  MenuItemMobileContainer,
  MobileIdiomsContainer,
} from "./MobileHeader.styled";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import ButtonComponent from "../Button";

const Header = () => {
  const { t, i18n } = useTranslation();

  const menuItems = [
    {
      title: t("start"),
      to: "start",
    },
    {
      title: t("our-safes"),
      to: "our-safes",
    },
    {
      title: t("how-works"),
      to: "how-works",
    },
    {
      title: t("who-we-are"),
      to: "who-we-are",
    },
    {
      title: t("technical-support"),
      to: "technical-support",
    },
    {
      title: t("contact"),
      to: "contact",
    },
  ];

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [atualIdiom, setAtualIdiom] = useState<string>(
    localStorage.getItem("psIdiom") || "pt"
  );

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "initial";
  }, [isOpen]);

  const changeIdiom = (idiom: string) => {
    if (idiom !== atualIdiom) {
      localStorage.setItem("psIdiom", idiom);
      window.location.reload();
    }
  };

  return (
    <>
      <FirstBar />
      <SecondBar />
      <ButtonComponent
        isFloating
        text={t("request-support")}
        to="https://atendimento.fieldcontrol.com.br/prime-security-solutions"
      ></ButtonComponent>
      <StickyContainer isSticky={isMobile} as={isMobile ? "header" : "div"}>
        <Container>
          <IdentityContainer>
            {isMobile && (
              <>
                <img src={Logo180} alt="Logo Prime Security" />
                <MenuBars
                  className="fas fa-bars"
                  color={psBlack}
                  whileTap={whileTap}
                  onClick={() => setIsOpen(true)}
                />
              </>
            )}
            {!isMobile && (
              <>
                <LogoContainer>
                  <img src={Logo240} alt="Logo Prime Security" />
                </LogoContainer>
                <IdiomsContainer>
                  <Idiom
                    src={Brasil25}
                    selected={atualIdiom === "pt"}
                    whileHover={{ opacity: 1 }}
                    onClick={() => changeIdiom("pt")}
                    alt="PT Idiom"
                  />
                  <Idiom
                    src={Spain25}
                    whileHover={{ opacity: 1 }}
                    selected={atualIdiom === "es"}
                    onClick={() => changeIdiom("es")}
                    alt="ES Idiom"
                  />
                  <Idiom
                    src={USA25}
                    whileHover={{ opacity: 1 }}
                    selected={atualIdiom === "en"}
                    onClick={() => changeIdiom("en")}
                    alt="EN Idiom"
                  />
                </IdiomsContainer>
              </>
            )}
          </IdentityContainer>
        </Container>
      </StickyContainer>
      {!isMobile && (
        <StickyContainer isSticky={!isMobile} as={!isMobile ? "header" : "div"}>
          <Container>
            <nav>
              <Menu>
                {menuItems.map((mi, i) => (
                  <>
                    <MenuItem
                      whileTap={whileTap}
                      whileHover={whileHover}
                      key={i}
                    >
                      <Link
                        to={mi.to}
                        smooth={true}
                        duration={500}
                        offset={-104.5}
                      >
                        <Text as="p" uppercase align="center" weight="pre-bold">
                          {mi.title}
                        </Text>
                      </Link>
                    </MenuItem>
                    {i !== 5 && <Circle />}
                  </>
                ))}
              </Menu>
            </nav>
          </Container>
        </StickyContainer>
      )}
      {isMobile && (
        <>
          <Drawer
            open={isOpen}
            onClose={() => setIsOpen(false)}
            direction="right"
            duration={250}
            className="psDrawer"
            zIndex={2000}
          >
            <FirstBar />
            <SecondBar />
            <MenuHeaderContainer>
              <div></div>
              <MenuBars
                className="fas fa-times"
                color={psBlack}
                whileTap={whileTap}
                onClick={() => setIsOpen(false)}
              />
            </MenuHeaderContainer>
            <MenuItemMobileContainer>
              {menuItems.map((mi, i) => (
                <MenuItem whileTap={whileTap} key={i}>
                  <Link
                    to={mi.to}
                    smooth={true}
                    duration={500}
                    offset={-115.5}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text as="p" uppercase align="center" weight="pre-bold">
                      {mi.title}
                    </Text>
                  </Link>
                </MenuItem>
              ))}
            </MenuItemMobileContainer>
            <MobileIdiomsContainer>
              <Idiom
                src={Brasil25}
                selected={atualIdiom === "pt"}
                onClick={() => changeIdiom("pt")}
                loading="lazy"
                alt="PT Idiom"
              />
              <Idiom
                src={Spain25}
                selected={atualIdiom === "es"}
                onClick={() => changeIdiom("es")}
                loading="lazy"
                alt="ES Idiom"
              />
              <Idiom
                src={USA25}
                selected={atualIdiom === "en"}
                onClick={() => changeIdiom("en")}
                loading="lazy"
                alt="EN Idiom"
              />
            </MobileIdiomsContainer>
          </Drawer>
        </>
      )}
    </>
  );
};

export default Header;
