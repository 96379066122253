import {
  Container,
  Footer,
  IconBack,
  IconBackContainer,
  Img,
} from "./Footer.styled";
import {
  ContactsContainer,
  IconType,
  NumberContainer,
} from "../../pages/Home/components/Contact/Contact.styled";
import { Text } from "../Text/Text.styled";
import { psRed } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import Logo180 from "../../assets/logo_180.png";
import { Link } from "react-scroll";
import { useMediaQuery } from "react-responsive";

const numbers = [
  { number: "(11) 4829-3414", link: "tel:48293414" },
  { number: "(11) 4829-3384", link: "tel:48293384" },
  { number: "(11) 4829-3594", link: "tel:48293594" },
];

const FooterComponent = () => {
  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    <Footer>
      <Container>
        <ContactsContainer>
          <Text as="h1" weight="pre-bold">
            {t("other-contacts")}
          </Text>
          {numbers.map((n, i) => (
            <NumberContainer>
              <IconType className="fas fa-phone-alt" color="#4C6EF5" />
              <Text key={i} as="a" href={n.link} target="_blank" color="link">
                {n.number}
              </Text>
            </NumberContainer>
          ))}
        </ContactsContainer>
        <Img src={Logo180} alt="Logo Prime Security" loading="lazy" />
        <Link
          to="start"
          smooth={true}
          duration={500}
          offset={isMobile ? -115.5 : -104.5}
        >
          <IconBackContainer>
            <IconBack className="fas fa-caret-up" color={psRed} />

            <Text as="p" uppercase size="accessible">
              {t("back-to-the-top")}
            </Text>
          </IconBackContainer>
        </Link>
      </Container>
      <Text weight="pre-bold" align="center">
        &copy; Prime Security Solutions - 2022 | CNPJ: 10.719.445/0001-80
      </Text>
    </Footer>
  );
};

export default FooterComponent;
