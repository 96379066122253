import styled from "styled-components";

interface IconContainerProps {
  color: string;
}

export const IconContainer = styled.div<IconContainerProps>`
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background: ${(props) => props.color};
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Arguments = styled.section`
  display: flex;
  justify-content: space-around;
  margin: 50px 0;
  flex-wrap: wrap;

  @media screen and (max-width: 767px) {
    margin: 0;
  }
`;

export const TextContainer = styled.div`
  max-width: 250px;
  margin-left: 15px;

  @media screen and (max-width: 350px) {
    margin: 15px;
  }
`;

export const Argument = styled.article`
  display: flex;
  padding: 0 15px;
  flex-wrap: wrap;

  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }

  @media screen and (max-width: 350px) {
    padding: 0;
    text-align: center;
  }

  @media screen and (max-width: 383px) {
    justify-content: center;
    padding: 0;
  }
`;
