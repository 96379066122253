import { motion } from "framer-motion";
import styled from "styled-components";
import { psRed } from "../../utils/constants";

export const Button = styled(motion.button)<any>`
  background-color: ${(props) => (props.isTransparent ? "transparent" : psRed)};
  padding: 10px 20px;
  border-radius: 10px;
  ${(props) =>
    props.isFloating
      ? `position:fixed; bottom: 50px; right: 50px; z-index: 4;`
      : ``}
  margin: 0 auto;
  border: ${(props) => (props.isTransparent ? `solid 2px ${psRed}` : "none")};

  box-shadow: 0 0 5px ${psRed};

  @media screen and (max-width: 768px) {
    bottom: 25px;
    right: 25px;
  }

  @media screen and (min-width: 769px) {
    cursor: pointer;
  }
`;
