import {
  AboutContainer,
  CircleModal,
  HeaderContainer,
  ImageContainer,
  ModalContainer,
  ModalHeader,
  ModalImg,
  Specification,
  VaultContainer,
} from "./Vault.styled";
import { Text } from "../../../../../components/Text/Text.styled";
import Button from "../../../../../components/Button";
import ReactModal from "react-modal";
import { useEffect, useState } from "react";
import { psBlack, whileHover, whileTap } from "../../../../../utils/constants";
import { MenuBars } from "../../../../../components/Header/MobileHeader.styled";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Section from "../../../../../components/Section";
import { useTranslation } from "react-i18next";

export interface VaultProps {
  id: Number;
  title: string;
  specifications: string;
  thumb: string;
  images: string[];
  detailedSpecifications: string[];
}

const responsive = {
  all: {
    breakpoint: {
      max: 4000,
      min: 0,
    },
    items: 1,
  },
};

const Vault: React.FC<VaultProps> = ({
  id,
  title,
  specifications,
  thumb,
  images,
  detailedSpecifications,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    document.body.style.overflow = modalIsOpen ? "hidden" : "initial";
  }, [modalIsOpen]);

  const { t, i18n } = useTranslation();

  return (
    <VaultContainer>
      <ImageContainer>
        <img src={thumb} alt={title} loading="lazy" />
      </ImageContainer>
      <AboutContainer>
        <Text
          weight="pre-bold"
          align="center"
          as="h1"
          className="margin-text-control"
          uppercase
        >
          {title}
        </Text>
        <Text as="p" align="justify" className="margin-text-control">
          {specifications}
        </Text>
        <Button text={t("see-details")} onClick={() => setModalIsOpen(true)} />
      </AboutContainer>
      <ReactModal
        isOpen={modalIsOpen}
        htmlOpenClassName={"ReactModal__Html--open"}
        shouldCloseOnOverlayClick={true}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: 6,
          },
          content: {
            width: "90vw",
            maxWidth: "600px",
            height: "90vh",
            position: "absolute",
            left: "5vw",
            top: "2vh",
            borderRadius: "10px",
            padding: "0",
            outline: "none",
            zIndex: 6,
          },
        }}
      >
        <ModalHeader>
          <HeaderContainer>
            <MenuBars
              className="fas fa-times"
              color={psBlack}
              whileTap={whileTap}
              whileHover={whileHover}
              onClick={() => setModalIsOpen(false)}
            />
          </HeaderContainer>
        </ModalHeader>

        <main>
          <Carousel
            responsive={responsive}
            deviceType=""
            ssr={true}
            slidesToSlide={1}
            draggable={false}
            swipeable={false}
            arrows
            renderArrowsWhenDisabled={false}
            minimumTouchDrag={0}
          >
            {images.map((im, i) => {
              return <ModalImg key={i} src={im} alt={title} loading="lazy" />;
            })}
          </Carousel>
          <ModalContainer>
            <Text as="h1" weight="pre-bold" align="center" uppercase>
              {title}
            </Text>
            <Section title={t("specifications")} isModal>
              {detailedSpecifications.map((d, i) => {
                return (
                  <Specification key={i}>
                    <CircleModal />
                    <Text>{d}</Text>
                  </Specification>
                );
              })}
            </Section>
          </ModalContainer>
        </main>
      </ReactModal>
    </VaultContainer>
  );
};

export default Vault;
