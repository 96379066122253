import { Button } from "./Button.styled";
import { Text } from "../../components/Text/Text.styled";
import { whileTap, whileHoverButton } from "../../utils/constants";

interface ButtonComponentProps {
  text: string;
  onClick?: () => void;
  isFloating?: true;
  to?: string;
  isTransparent?: boolean;
}

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  text,
  onClick,
  isFloating,
  to,
  isTransparent
}) => {
  return to ? (
    <a href={to} target="_blank" rel="noreferrer" className="non-decored">
      <Button
        whileTap={whileTap}
        whileHover={whileHoverButton}
        onClick={onClick}
        isFloating={isFloating}
        isTransparent={isTransparent}
      >
        <Text color="white" as="a" weight="pre-bold" size="button">
          {text}
        </Text>
      </Button>
    </a>
  ) : (
    <Button
      whileTap={whileTap}
      whileHover={whileHoverButton}
      onClick={onClick}
      isFloating={isFloating}
      isTransparent={isTransparent}
    >
      <Text color="white" as="a" weight="pre-bold" size="button">
        {text}
      </Text>
    </Button>
  );
};

export default ButtonComponent;
